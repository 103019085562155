<template>
	<div>
		<!-- 登录信息 -->
		<global-tips></global-tips>
		<el-card>
			<!-- 搜索框 -->
			<el-form v-show="showSearch" ref="queryForm" :model="queryForm" inline size="small">
				<el-form-item label="名称" label-width="50px" prop="keyword">
					<el-input v-model="queryForm.keyword" placeholder="请输入名称" type="text" clearable
						style="width: 230px" />
				</el-form-item>
				<el-form-item>
					<el-button type="primary" icon="el-icon-search" size="mini" @click="query">查询</el-button>
					<el-button icon="el-icon-refresh" size="mini" @click="resetQuery('queryForm')">重置</el-button>
				</el-form-item>
			</el-form>
			<!--自定义刷新组件-->
			<el-row :gutter="10" class="mb-1">
				<!-- <el-button type="primary" icon="el-icon-plus" size="mini" @click="handleAdd">新增</el-button> -->
				<right-toolbar :show-search.sync="showSearch" @queryTable="query" />
			</el-row>
			<!--表格-->
			<el-table v-loading="loading" size="small" :data="list" element-loading-text="Loading" highlight-current-row
				style="width: 100%">
				<el-table-column label="ID" width="80" align="center">
					<template slot-scope="scope">
						{{ (queryForm.page - 1) * queryForm.limit + scope.$index + 1 }}
					</template>
				</el-table-column>
				<el-table-column label="日期" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.date }}</span>
					</template>
				</el-table-column>
				<el-table-column label="名称" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.name }}</span>
					</template>
				</el-table-column>
				<el-table-column label="学号" min-width="110" align="center">
					<template slot-scope="scope">
						<span>{{ scope.row.code }}</span>
					</template>
				</el-table-column>
				<el-table-column label="年级" min-width="110" align="center">
					<template slot-scope="scope">
						{{ scope.row.nianji }}
					</template>
				</el-table-column>
				<el-table-column label="班级" min-width="110" align="center">
					<template slot-scope="scope">
						{{ scope.row.banji }}
					</template>
				</el-table-column>
				<el-table-column align="center" prop="ctime" label="时间" min-width="160">
					<template slot-scope="scope">
						<i class="el-icon-time" />
						<span>{{ scope.row.ctime }}</span>
					</template>
				</el-table-column>
				<el-table-column label="操作" min-width="100" align="center">
					<template slot-scope="scope">
						<el-tooltip class="item" effect="dark" content="查看" placement="top">
							<el-button type="primary" icon="el-icon-view" size="mini" circle
								@click="handleView(scope.row)"></el-button>
						</el-tooltip>
						<el-tooltip class="item" effect="dark" content="删除" placement="top">
							<el-button type="danger" icon="el-icon-delete" size="mini" circle
								@click="handleDelete(scope.row.id)"></el-button>
						</el-tooltip>
					</template>
				</el-table-column>
			</el-table>
			<pagination :total="total" auto-scroll :page.sync="queryForm.page" :limit.sync="queryForm.limit"
				@pagination="query" />
		</el-card>
		<!-- 编辑-->
		<el-dialog :visible.sync="open" width="50%" :close-on-click-modal="false" append-to-body :title="title">
			<div class="w-100 flex flex-column" v-if="params.length > 0" v-for="(item,index) in params" :key="index">
				<el-divider content-position="left">{{index+1}}.{{item.name || ''}}</el-divider>
				<div class="w-100 flex align-center" v-for="(val,key) in item.values" :key="val.id">
					<div class="flex-1 flex align-center mb-2">
						<div class="flex align-center opacity-60" style="width: 100px;">
							{{val.title}}：
						</div>
						<div class="flex-1">
							<span v-if="val.value == 1">A</span>
							<span v-else-if="val.value == 2">B</span>
							<span v-else-if="val.value == 3">C</span>
							<span v-else-if="val.value == 4">D</span>
						</div>
					</div>
				</div>
			</div>


<!--            <el-divider content-position="left">8.每天晚上睡觉时间？</el-divider>
            <div class="w-100 flex align-center">
            	<div class="flex-1 flex align-center mb-2">
            		<div class="flex-1 pl-2">
            			<span class="mr-2">{{ params.sj_time || '' }}</span>
            			<span v-if="params.sj_time == 'A'">晚上10-11点之间</span>
            			<span v-if="params.sj_time == 'B'">晚上11-12点之间</span>
            			<span v-if="params.sj_time == 'C'">晚上12点-凌晨1点之间</span>
            			<span v-if="params.sj_time == 'D'">凌晨1-凌晨2点之间</span>
            		</div>
            	</div>
            </div> -->
		</el-dialog>
	</div>
</template>

<script>
	import GlobalTips from "@/components/GlobalTips";
	import common from "@/common/mixins/common.js"
	export default {
		inject: ['app'],
		mixins: [common],
		components: {
			GlobalTips,
		},
		data() {
			return {
				preUrl: 'student_record',
				queryForm: {
					page: 1,
					limit: 10,
					keyword: '',
				},
				form: {
					username: '',
					password: '',
					mobile: '',
					role_ids: [],
					status: 1,
					avatar: '',
					url: '',
				},
				rules: {
					username: [{
						required: true,
						message: '请输入名称',
						trigger: 'blur'
					}],
					role_ids: [{
						required: true,
						message: '请选择角色',
						trigger: ['blur', 'change']
					}],
				},
				params: {},
			}
		},
		mounted() {

		},
		methods: {
			reset() {
				this.form = {
					username: '',
					password: '',
					mobile: '',
					role_ids: [],
					status: 1,
					avatar: '',
					url: '',
				}
				this.resetForm('form')
			},
			handleView(row) {
				this.params = row.params
				this.open = true
				this.title = '调查详情'
			},
		}
	}
</script>

<style>
</style>